@keyframes placeWall {
	0% {
		opacity: 0;
		transform: scale(0);
	}
	50% {
		opacity: 0.7;
		transform: scale(4);
	}
	100% {
		opacity: 1;
		transform: scale(1);
	}
}
